// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/
var Utils;

Utils = class Utils {
    static hideNotice() {
        var notice;
        notice = $(".notice");
        if (notice.length > 0) {
            return setTimeout(this.timeOut, 3000);
        }
    }

    static timeOut() {
        return $(".notice").fadeOut("slow", function() {
            return $(".notice").remove();
        });
    }

};

$(function() {
    Utils.hideNotice();
    return $(".btn").addClass("no-print");
});
