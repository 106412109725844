// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/
$(function() {
  var content;
  $(document).on("click", ".remove_row", function() {
    return $(this).parents("tr").remove();
  });
  return $(".add_row").on("click", function() {
    content = $( ".clone_me" ).first().clone().removeClass('hidden').removeClass('clone_me');
    return $("#individual_table").append(content);
  });
});
