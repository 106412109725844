// This is a manifest file that'll be compiled into including all the files listed below.
// Add new JavaScript/Coffee code in separate files in this directory and they'll automatically
// be included in the compiled file accessible from http://example.com/assets/application.js
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// the compiled file.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("packs/custom/bootstrap")
require("packs/custom/coupons")
require("packs/custom/subscriptions")
require("packs/custom/update_content")
require("packs/custom/jquery.cookie")
require("packs/custom/individuals")
require("packs/custom/global")
require("packs/custom/entities")
require("packs/custom/nicepage")
import $ from 'jquery';
import select2 from 'select2';

$(document).ready(function () {
    $(document).on("change", "*[name='search_type']", function (e) {
        var id = $(this).attr('id');
        if (id == 'search_type_business') {
            $(".person").hide();
            $(".business").show();
        } else {
            $(".business").hide();
            $(".person").show();
        }
    });
    $(document).on("click", ".back-button", function (e) {
        e.preventDefault();
        history.go(-1);
    });

    $('.multiselect').select2({
        maximumSelectionLength: 3
    })

    $("#blogs_left_scroll").on('click', function(){
        console.log('left')
        $( "div.blogs" ).animate({scrollLeft: '-=1000'},1000);
    });

    $("#blogs_right_scroll").on('click', function(){
        console.log('right')
        $( "div.blogs" ).animate({scrollLeft: '+=1000'},1000);
    });
});

