// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://coffeescript.org/
$(function() {
  $('#calculate-total-button').click(function() {
    var coupon_code, plan_price;
    coupon_code = $('#subscription_coupon_code').val();
    plan_price = $('#subtotal-price-label').text();
    $.ajax({
      type: 'POST',
      url: '/check-coupon-code',
      data: {
        coupon_code: coupon_code,
        plan_price: plan_price
      },
      success: function(data) {
        if (data.constructor.name === 'String') {
          $('#coupon-offer-label').text(data);
          $('#coupon-discount-label').text('0.00');
          $('#total-price-label').text(parseFloat(plan_price).toFixed(2));
          return $('#subscription_coupon_code').val('');
        } else {
          $('#coupon-offer-label').text(data.coupon_offer);
          $('#coupon-discount-label').text(data.discount.toFixed(2));
          $('#total-price-label').text(data.total_price.toFixed(2));
        }
      }
    });
  });
});
