$(function () {
    var $stripeForm = $("form[data-stripe-form]");

    var stripeResponseHandler = function (status, response) {
        if (response.error) {
            alert(response.error.message);
            var $form = $stripeForm;
            $form.find('.btn-stripe').prop('disabled', false);
        } else {
            var $form = $stripeForm;
            var token = response['id'];
            $form.find("[data-stripe-token]").val(token);
            $form.get(0).submit();
        }
    };

    // when using stripe, we want to hold off on submitting until stripe returns
    // with the card token
    if (typeof Stripe === 'function' && !$("#card_number").prop('disabled')) {
        $stripeForm.submit(function (e) {
            var $form = $(this);
            $form.find('.btn-stripe').prop('disabled', true);
            Stripe.card.createToken($form, stripeResponseHandler);
            return false;
        });
    }

    $('#user_selected_plan').on('change', function(){
       $('#subscription_change_form').submit();
    });
});
