// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/
var ReportTimer;

ReportTimer = class ReportTimer {
	static start() {
		var ok;
		ok = $(".report_id").length > 0;
		if (ok) {
			return ReportTimer.runTimer();
		}
	}

	static showFinishedFields(obj) {
		$(obj).parent().find(".report_download_options").show();
		$(obj).parent().find(".report_status").html("<strong>Your reports are ready to download.</strong><br /><br />");
		$(obj).parent().find(".progress").removeClass("active");
		return $(obj).remove();
	}

	static runTimer() {
		$('.report_id').each(function(_, obj) {
			var report_id;
			report_id = $(obj).val();
			return $.get(`poll?report_id=${report_id}&format=json`, function(data) {
				$(obj).parent().find(".report_status_update").html(`${data.status}.`);
				$(obj).parent().find(".bar").attr("style", `width:${data.progress}%;`);
				if (data.ready === true) {
					return ReportTimer.showFinishedFields(obj);
				}
			});
		});
		if ($(".report_id").length > 0) {
			return setTimeout(ReportTimer.runTimer, 1000);
		}
	}

};

$(function() {
	return ReportTimer.start();
});

// $("#generate_report").on "click", (e)->
// $("#summary_report").show()
